import React from "react";

export const Navigation = (props) => {
  // Asegúrate de que 'img/logo.jpg' esté disponible en tu proyecto
  const logo = "img/logo.jpg";

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header" style={{ display: 'flex', alignItems: 'center' }}>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top" style={{ fontSize: '15px', display: 'flex', alignItems: 'center' }}>
            {/* <img src={logo} alt="Logo" style={{ maxHeight: '60px', marginRight: '10px' }} /> */}
            <span style={{ fontFamily: 'Malkone Light', fontWeight: 300 }}>
              R&E ANDAMIOS CELESTE
            </span>
          </a>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#services" className="page-scroll">
                Servicios
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contacto
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Acerca de
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                Características
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Galería
              </a>
            </li>
            <li>
              <a href="#Dirección" className="page-scroll">
              ENCUENTRANOS EN...
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};
